.parent {
    display: flex;
    flex-direction: row;
}

.one {
    height: 35px;
    width: 35px;
    background-color: #0000ff;
}

.two-left {
    display: flex;
    flex-direction: row;
}

.two-left {
    min-width: 35px;
    min-height: 35px;
    border-top: 0px;
}

.drip,
.drip2,
.drip3,
.drip4,
.drip5,
.drip6,
.drip7 {
    height: 5px;
    width: 5px;
    background-color: #0000ff;
}

.two-main {
    min-height: 100vh;
    padding-left: 7px;
}

.name {
    color: black;
    font-size: 25px;
}

.header {
    color: #808080;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
}

.location,
.externalLink {
    display: flex;
    align-items: center;
    padding: 5px;
    width: 110px;
}

.externalLink {
    background-color: #dfdffd;
    margin-bottom: 10px;
}

.mapPin {
    height: 20px;
    margin-right: 5px;
}

.externalIcon {
    margin-right: 10px;
    height: 24px;
}

.titleText {
    font-size: 20px;
}

.link {
    text-decoration: none;
}

.link,
.link:hover,
.link:visited {
    color: black;
}

.link:hover {
    cursor: pointer;
    text-decoration: underline;
}
